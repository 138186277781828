<div class="bet-insight-wrapper" [class.collapsed]="collapsed" *ngIf="betInsights.length > 0">
  <div class="bet-insight-header" (click)="collapsed = !collapsed">
    <div class="title">
      <i class="icon"></i>
      <span i18n>Bet Insights </span>
      <span class="indicator-text">
        <span class="indicator" i18n>New</span>
      </span>
    </div>
    <i class="fa fa-angle-down" aria-hidden="true" [appDropdownArrowRotator]="!collapsed" [rotateDegrees]="-180"></i>
  </div>
  <div class="bet-insight-content" [class.hidden]="collapsed" appAdjustContainerOnHorizontalScroll>
    <app-bet-insight-card [eventLocation]="eventLocation" *ngFor="let betInsight of betInsights" [betInsight]="betInsight" [showInfo]="showInfo"></app-bet-insight-card>
  </div>
</div>
