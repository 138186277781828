import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { BetCoupon, BetCouponGlobalVariable, BetCouponOdd, CouponType, Dictionary, StringDictionary } from 'clientside-coupon';
import { filter, map } from 'rxjs/operators';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { CouponReceiptPhoneVerificationContentModel } from 'src/app/shared/models/coupon-receipt.model';
import {
  BookedCoupon,
  CouponSettings,
  CouponState,
  CouponUIState,
  FlexicutOptionModel,
  DefaultCouponStake,
  ExpiredEventsModel,
  OddChanges,
} from 'src/app/shared/models/coupon.model';
import { MarketModel, MatchModel, SelectionModel, SportModel } from 'src/app/shared/models/sport.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponStore } from './coupon.store';

@Injectable({ providedIn: 'root' })
export class CouponQuery extends Query<CouponState> {
  bookedCoupons$ = this.select(state => state.bookedCoupons);
  couponInitialized$ = this.select(state => state.couponInitialized);
  hasCouponSelections$ = this.select(state => !!state.couponData && state.couponData.Odds.length > 0);
  couponData$ = this.select(state => state.couponData);
  lastPlacedCoupon$ = this.select(state => state.lastPlacedCoupon);
  lastPlacedCouponCode$ = this.select(state => state.lastPlacedCoupon.couponCode);
  lastPlacedCouponBookingCode$ = this.select(state => state.lastPlacedCoupon.bookingCode);
  lastPlacedCouponAccaBonus$ = this.select(state =>
    // Have to recalculate percentage because BE are zeroing out the percentage value, but sending bonus amount
    state.lastPlacedCoupon.MaxPercentageBonus || state.lastPlacedCoupon.MaxBonus
      ? Math.round((state.lastPlacedCoupon.MaxBonus / state.lastPlacedCoupon.MaxWin) * 100)
      : 0
  );
  lastPlacedCouponFlexicut$ = this.select(state => state.lastPlacedCoupon.BetDetails?.FlexiCutDetails?.Cut);
  lastPlacedCouponIsFreebet$ = this.select(state => Boolean(state.lastPlacedCoupon.BetDetails?.FreeBetDetails?.code));
  couponDataFiltered$ = this.select(state => state.couponData).pipe(
    map(couponData => {
      if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
        return undefined;
      }
      return couponData;
    })
  );
  couponSelectionIds$ = this.select(state => (state.couponData ? state.couponData.Odds.map(odd => odd.SelectionId) : [])); //
  couponSettings$ = this.select(state => this.initCouponSettings(state.couponSettings));
  defaultCouponStake$ = this.select(state => state.defaultCouponStake);
  editCouponData$ = this.select(state => state.editCouponData);
  oddChanges$ = this.select(state => state.oddChanges);
  editCouponSelectionMarket$ = this.editCouponData$.pipe(
    map(data => {
      return new MarketModel({
        id: data.odds[0].marketId,
        typeId: data.odds[0].marketTypeId,
        selections: this.getEditCouponSelections(data),
      });
    })
  );
  editCouponSelectionHeaderData$ = this.editCouponData$.pipe(
    map(data => {
      return this.getEditCouponSelections(data);
    })
  );

  couponforEdit$ = this.select(state => state.ui.couponForEdit);
  groupingsTabSelected$ = this.select(state => state.groupingsTabSelected);
  showCoupon$ = this.select(state => state.ui.showCoupon);
  showQuickCoupon$ = this.select(state => state.ui.showQuickCoupon);
  hasExpiredOdds$ = this.select(
    state => state.expiredEvents?.expiredEvents.length > 0 || (state.couponData ? state.couponData.Odds.some(odd => odd.IsExpired) : false)
  );
  hasLockedOdds$ = this.select(state => (state.couponData ? state.couponData.Odds.some(odd => odd.IsLocked) : false));
  selections$ = this.select(state => state.selections);
  groupedSelections$ = this.select(state => state.groupedSelections);
  selectionMarketMatches$ = this.select(state => state.selectionMarketMatches);
  expiredEvents$ = this.select(state => state.expiredEvents);
  couponHasLiveEvents$ = this.select(state => (state.couponData ? state.couponData.HasLive : false));
  minBetStake$ = this.select(state => (state.globalVariables ? state.globalVariables.MinBetStake : false));
  couponReceiptContent$ = this.select(state => state.couponReceiptContent);
  hasBookedBetData$ = this.select(state => !!state.bookedBetData);
  bookedBetData$ = this.select(state => state.bookedBetData);
  bestSellerCoupons$ = this.select(state => state.bestSellerCoupons);
  emptyBetslipQuicklinks$ = this.select(state => state.emptyBetslipQuicklinks);
  couponReceiptPhoneVerificationContent$ = this.select(state => state.couponReceiptPhoneVerificationContent);
  couponTotalOdds$ = this.select(state => {
    if (state.couponData) {
      return state.couponData.CouponType === CouponType.System ? state.couponData.MaxOdd : state.couponData.TotalOdds;
    } else {
      return 0;
    }
  });

  // Freebets
  invalidFreebetSelections$ = this.select(state => (state.couponData?.BetDetails?.FreeBetDetails ? state.invalidFreebetSelections : []));
  hasInvalidFreebetSelections$ = this.select(
    state => state.couponData?.BetDetails?.FreeBetDetails && state.invalidFreebetSelections?.length > 0
  );

  // Flexicut
  flexicutOdds$ = this.select(state => state.flexicutResponse).pipe(map(response => (response ? response.cutOdds.result : [])));
  isFlexicutCoupon$ = this.select(state => (state.couponData ? state.couponData.CouponTypeId === CouponType.Flexicut : false));
  isFlexicutServiceInitialised$ = this.select(state => state.isFlexicutServiceInitialised);
  oddsBelowFlexicutMinOdds$ = this.select(state =>
    this.selectionOdds.filter(odd => odd < (state.globalVariables ? state.globalVariables.MinFlexiCutOdds : 0))
  );
  flexicutMinSelections$ = this.select(state => state.globalVariables?.MinFlexiCutSelections).pipe(filter(val => !!val));
  invalidFlexicutSelections$ = this.select(state => (state.couponData?.BetDetails?.FlexiCutDetails ? state.invalidFlexicutSelections : []));
  hasInvalidFlexicutSelections$ = this.select(
    state => state.couponData?.BetDetails?.FlexiCutDetails && state.invalidFlexicutSelections?.length > 0
  );
  flexicutSelectedOption$ = this.select(state => state.flexicutSelectedOption);
  showFlexicutBanner$ = this.select(
    state =>
      (state.couponData?.CouponTypeId === CouponType.Multiple || state.couponData?.CouponTypeId === CouponType.Flexicut) &&
      state.couponData?.Odds.length >= 2
  );
  isFlexicutApplicableUserTypeCheck$ = combineLatest([this.accountQuery.isAuthenticated$, this.accountQuery.userData$]).pipe(
    map(([isAuth, userData]) => {
      const couponConfig = this.appConfigService.get('sports').coupon;
      const slowRolloutTrafficPercentage = couponConfig.flexicutTrafficPercent;

      // If we have slow rollout enabled (% is less than 100), do not show to users without auth.
      if (slowRolloutTrafficPercentage < 100) {
        return (
          isAuth &&
          userData.id % 100 < slowRolloutTrafficPercentage &&
          (couponConfig.flexicutWhitelistedUserTypes as string[]).includes(this.accountQuery.userData.userTypeCode)
        );
      } else {
        return !isAuth || (couponConfig.flexicutWhitelistedUserTypes as string[]).includes(this.accountQuery.userData.userTypeCode);
      }
    })
  );
  isFlexicutApplicableCouponCheck$ = combineLatest([this.couponData$, this.flexicutMinSelections$]).pipe(
    map(([couponData, flexicutMinSelections]) => {
      if (couponData) {
        return (
          (couponData.CouponTypeId === CouponType.Multiple || couponData.CouponTypeId === CouponType.Flexicut) &&
          couponData.Odds.length >= flexicutMinSelections
        );
      }
      return false;
    })
  );
  isFlexicutApplicable$ = combineLatest([this.isFlexicutApplicableUserTypeCheck$, this.isFlexicutApplicableCouponCheck$]).pipe(
    map(([userTypeCheck, couponCheck]) => userTypeCheck && couponCheck)
  );

  // Bet Builder
  hasDynamicBetBuilderSelections$ = this.couponData$.pipe(
    map(couponData => couponData?.Odds.some(odd => odd.IsBetBuilder && odd.MarketTypeId !== this.preCannedBetBuilderMarketTypeID))
  );

  private isFlexicutApplicableState: boolean = false;

  constructor(
    protected store: CouponStore,
    private readonly accountQuery: AccountQuery,
    private readonly appConfigService: AppConfigService
  ) {
    super(store);

    this.isFlexicutApplicable$.subscribe(isApplicable => {
      this.isFlexicutApplicableState = isApplicable;
    });
  }

  get showCoupon(): boolean {
    return this.getValue().ui.showCoupon;
  }

  get showQuickCoupon(): boolean {
    return this.getValue().ui.showQuickCoupon;
  }

  get uiState(): CouponUIState {
    return this.getValue().ui;
  }

  get couponData(): BetCoupon {
    const couponData: BetCoupon = this.getValue().couponData;
    if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
      return undefined;
    }
    return couponData;
  }

  get lastPlacedCoupon(): CouponState['lastPlacedCoupon'] {
    return this.getValue().lastPlacedCoupon;
  }

  get selectedForEdit(): { matchId: number; marketTypeId: number } {
    return this.getValue().ui.couponForEdit;
  }

  get couponSettings(): CouponSettings {
    return this.initCouponSettings(this.getValue().couponSettings);
  }

  get defaultCouponStake(): DefaultCouponStake {
    return this.getValue().defaultCouponStake;
  }

  get oddChanges(): OddChanges[] {
    return this.getValue().oddChanges;
  }

  get expiredEvents(): ExpiredEventsModel {
    return this.getValue().expiredEvents;
  }

  get bookedCoupons(): BookedCoupon[] {
    const bookedCoupons = this.getValue().bookedCoupons;
    if (bookedCoupons === null || bookedCoupons === undefined) {
      return [];
    }
    return bookedCoupons;
  }

  get couponInitialized(): boolean {
    return this.getValue().couponInitialized;
  }

  get globalVariables(): BetCouponGlobalVariable {
    return this.getValue().globalVariables;
  }

  get marketExceptions(): Dictionary<number, number[]> {
    return this.getValue().marketExceptions;
  }

  get correctScoreOddsMatrix(): any {
    return this.getValue().correctScoreOddsMatrix;
  }

  get groupingsTabSelected(): any {
    return this.getValue().groupingsTabSelected;
  }

  get correctScoreOddsMatrixData(): StringDictionary<string[]> {
    const correctScoreOddsMatrix = this.getValue().correctScoreOddsMatrix;
    if (!correctScoreOddsMatrix) {
      return correctScoreOddsMatrix;
    }
    return correctScoreOddsMatrix.data;
  }

  get hasCouponData(): boolean {
    return !!this.getValue().couponData;
  }

  get couponReceiptConfirmationForwardCTAURL(): string {
    const content = this.getValue().couponReceiptContent;
    return content ? content.confirmationForwardCTAURL : '/';
  }

  get couponReceiptUpsellForwardCTAURL(): string {
    const content = this.getValue().couponReceiptContent;
    return content ? content.upsellForwardCTAURL : '/';
  }

  get couponReceiptConfirmationNormalBetHeader(): string {
    const content = this.getValue().couponReceiptContent;
    return content ? content.confirmationNormalBetHeader : '';
  }

  get couponReceiptConfirmationFreeBetHeader(): string {
    const content = this.getValue().couponReceiptContent;
    return content ? content.confirmationFreeBetHeader : '';
  }

  get hasBookedBetData(): boolean {
    return !!this.getValue().bookedBetData;
  }

  get bookedBetData(): BookBetModel {
    return this.getValue().bookedBetData;
  }

  get hasCouponReceiptPhoneVerificationContent(): boolean {
    return !!this.getValue().couponReceiptPhoneVerificationContent;
  }

  get hasCouponReceiptContent(): boolean {
    return !!this.getValue().couponReceiptContent;
  }

  get couponReceiptPhoneVerificationContent(): CouponReceiptPhoneVerificationContentModel {
    return this.getValue().couponReceiptPhoneVerificationContent;
  }

  get previousPagePath(): string {
    return this.getValue().previousPagePath;
  }

  get lastPlacedCouponCode(): string {
    return this.getValue().lastPlacedCoupon.couponCode;
  }

  get betslipScrollTop(): number {
    return this.getValue().betslipScrollTop;
  }

  get isLastPlacedCouponInEvaluation(): boolean {
    return this.getValue().lastPlacedCoupon?.CurrentEvalReason !== 0;
  }

  get hasFlexicutOdds(): boolean {
    const fcRes = this.getValue().flexicutResponse;
    return fcRes ? fcRes.cutOdds.result.length > 0 : false;
  }

  get flexicutOdds(): number[] {
    const fcRes = this.getValue().flexicutResponse;
    return fcRes.cutOdds?.result;
  }

  get isFlexicutCoupon(): boolean {
    const coupon = this.getValue().couponData;
    return coupon ? coupon.CouponTypeId === CouponType.Flexicut : false;
  }

  get groupedSelections(): SportModel[] {
    return this.getValue().groupedSelections;
  }

  get selectionOdds(): number[] {
    return this.groupedSelections
      ? this.groupedSelections.flatMap(selection =>
          selection.categories.flatMap(category =>
            category.tournaments.flatMap(tournament => tournament.matches.flatMap(match => match.odds.map(odd => odd.value)))
          )
        )
      : [];
  }

  get isFlexicutApplicable(): boolean {
    return this.isFlexicutApplicableState;
  }

  get bestSellerBookingCountThreshold(): number {
    return this.appConfigService.get('sports').coupon.bestSellerBookingCountThreshold || 0;
  }

  get preCannedBetBuilderMarketTypeID(): number {
    return this.appConfigService.get('sports').betBuilderMarketTypeID || -1;
  }

  get dynamicBetBuilderSelectionsCount(): number {
    return this.couponData?.Odds.filter(odd => odd.IsBetBuilder && odd.MarketTypeId !== this.preCannedBetBuilderMarketTypeID).length || 0;
  }
  get precannedBetBuilderSelectionsCount(): number {
    return this.couponData?.Odds.filter(odd => odd.IsBetBuilder && odd.MarketTypeId === this.preCannedBetBuilderMarketTypeID).length || 0;
  }

  get lastPlacedCouponBookingCode(): string {
    return this.getValue().lastPlacedCoupon.bookingCode;
  }

  get showGoalNotificationsSwitchForNativeApps(): boolean {
    return this.appConfigService.get('sports').coupon.showGoalNotificationsSwitchForNativeApps || false;
  }

  couponContainsOddChanges = (): boolean => !this.getValue().oddChanges?.every(o => o.initialOddValue === o.latestOddValue);

  getSameMatchSelection(matchId: number): BetCouponOdd {
    return this.getValue().couponData ? this.getValue().couponData.Odds.find(o => o.MatchId === matchId) : undefined;
  }

  getInvalidFreebetSelections = (): number[] => {
    const { couponData, invalidFreebetSelections } = this.getValue();

    return couponData.BetDetails?.FreeBetDetails ? invalidFreebetSelections : [];
  };

  getInvalidFlexicutSelections = (): number[] => {
    const { couponData, invalidFlexicutSelections } = this.getValue();
    return couponData.BetDetails?.FlexiCutDetails?.Cut ? invalidFlexicutSelections : [];
  };

  getFlexicutOddForACutNumber(cut: number): number {
    return this.getValue().flexicutResponse?.cutOdds.result[cut - 1];
  }

  getFlexicutSelectedOption = (): FlexicutOptionModel => {
    return this.getValue().flexicutSelectedOption;
  };

  // check if coupon settings are undefined and update default values for it
  private readonly initCouponSettings = (couponSettings: CouponSettings) => ({
    ...couponSettings,
    allowOddChanges: couponSettings?.allowOddChanges === undefined ? true : couponSettings.allowOddChanges,
    lastAllowOddChanges: couponSettings?.lastAllowOddChanges === undefined ? true : couponSettings.lastAllowOddChanges,
    allowCompetitionGrouping: couponSettings?.allowCompetitionGrouping === undefined ? false : couponSettings.allowCompetitionGrouping,
  });

  private readonly getEditCouponSelections = (data: MatchModel) => {
    const selections: SelectionModel[] = [];
    if (data) {
      data.odds.forEach(element => {
        selections.push({
          id: element.marketId,
          name: element.selectionName,
          shortcut: '',
          spreadValue: typeof element.spreadValue === 'number' ? element.spreadValue : undefined,
          spreadDisplayValue: typeof element.spreadValue === 'string' ? element.spreadValue : element.spreadValue.toString(),
          order: -1,
        });
      });
    }

    return selections;
  };
}
